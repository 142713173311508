<template>
  <q-card
    square
    bordered
    flat
  >
    <q-item
      :focused="checkoutStore.isInsured"
      :disable="checkoutStore.isPickup || quoteStore.pending"
      clickable
      @click="checkoutStore.isInsured = !checkoutStore.isInsured"
    >
      <q-item-section avatar>
        <q-checkbox
          v-model="checkoutStore.isInsured"
          :disable="checkoutStore.isPickup || quoteStore.pending"
          color="secondary"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-weight-bold">
          Protect Your Shipments with KYGUNCO's Shipping Insurance
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-slide-transition>
      <q-card-section
        v-show="checkoutStore.isInsured"
        class="column q-gutter-sm text-body2 no-scroll"
      >
        <div>
          <ul class="no-margin q-pl-md">
            <li>Coverage against loss, theft, or damage in transit.</li>
            <li>Call or email customer service for resolution of shipping issues.</li>
            <li>Item refund or replacement, pending availability.</li>
            <li>Claims need to be reported within 24 hours of delivery.</li>
            <li>Customer Support Mon - Sat: 9am-5pm</li>
          </ul>
        </div>
      </q-card-section>
    </q-slide-transition>
  </q-card>
</template>

<script lang="ts" setup>
const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
</script>
