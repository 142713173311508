<template>
  <q-card
    square
    bordered
    flat
  >
    <q-item
      :focused="active"
      :disable="pending"
      clickable
      @click="active = !active"
    >
      <q-item-section avatar>
        <q-checkbox
          v-model="active"
          :disable="pending"
          color="secondary"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-weight-bold">
          Use KYGUNCO Gift Cards
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-slide-transition>
      <q-card-section v-show="active">
        <div class="row items-center q-col-gutter-md">
          <q-input
            ref="codeRef"
            v-model="code"
            :disable="pending"
            :rules="[
              (val: string) => !!val || 'Gift card code is required.',
              (val: string) => val.length <= 30
                || 'Gift card code must be less or equal to 30 characters.',
              (val: string) => !appliedCodes.includes(val)|| 'Gift card code already applied.',
            ]"
            hint="Type in your gift card code."
            maxlength="30"
            class="col-12 col-md-6"
            counter
            square
            filled
            @keyup.enter="submit()"
          >
            <template
              v-if="code?.length"
              #append
            >
              <q-btn
                :icon="mdiPlus"
                flat
                round
                @click="submit()"
              />
            </template>
          </q-input>
          <div
            v-for="item in items"
            :key="item.code"
            class="col-12 col-md-6"
          >
            <div class="row justify-between items-center">
              <div class="text-overline">
                {{ item.code }}
              </div>
              <div>
                <a
                  class="text-link text-subtitle2 cursor-pointer"
                  @click="remove(item.code)"
                >
                  Remove
                </a>
              </div>
            </div>
            <q-linear-progress
              :value="(item.original - item.balance) / item.original"
              color="positive"
              size="40px"
            >
              <div class="absolute-full flex flex-center">
                <div class="text-subtitle1 text-white">
                  Used {{ currency(item.original - item.balance) }} /
                  {{ currency(item.original) }}
                </div>
              </div>
            </q-linear-progress>
          </div>
        </div>
      </q-card-section>
    </q-slide-transition>
  </q-card>
</template>

<script lang="ts" setup>
import { mdiPlus } from '@quasar/extras/mdi-v7';
import type { QInput } from 'quasar';
import type { FetchError } from 'ofetch';
import type { ProblemDetails, StoreCreditResponse } from '~/types/ecommerce';

const { $ecommerce } = useNuxtApp();
const quoteStore = useQuoteStore();
const checkoutStore = useCheckoutStore();
const { currency } = useFormatting();
const { notify } = useQuasar();

const active = ref(false);
const submitting = ref(false);
const codeRef = ref<QInput | null>(null);
const code = ref<string | null>(null);

const pending = computed(() => submitting.value || quoteStore.pending);

const appliedCodes = computed(() => quoteStore.data?.usedStoreCredits?.map(i => i.code) ?? []);

const items = computed(
  () => checkoutStore.storeCredits.filter(i => appliedCodes.value.includes(i.code)));

const remove = (code: string) =>
  checkoutStore.storeCredits = checkoutStore.storeCredits.filter(i => i.code != code);

const submit = async () => {
  if (!await codeRef.value?.validate()) {
    return;
  }

  submitting.value = true;

  await $ecommerce.fetch<StoreCreditResponse>('sales/store-credits', {
    params: {
      code: code.value,
    },
  }).then((entity) => {
    checkoutStore.storeCredits.push(entity);
    code.value = null;
    codeRef.value?.resetValidation();
  }).catch((error: FetchError<ProblemDetails>) => {
    if (error.status == 404) {
      notify({
        type: 'negative',
        message: 'Invalid gift card code. Please try tryping the code again or contact Customer Service.',
      });

      return;
    }

    $ecommerce.handle(error);
  });

  submitting.value = false;
};

watch(active, value => value || (checkoutStore.storeCredits = []));
</script>
