<template>
  <q-card
    square
    bordered
    flat
  >
    <q-item
      :focused="checkoutStore.isLayaway"
      :disable="!quoteStore.data?.isLayawayAvailable || quoteStore.pending"
      clickable
      @click="checkoutStore.isLayaway = !checkoutStore.isLayaway"
    >
      <q-item-section avatar>
        <q-checkbox
          v-model="checkoutStore.isLayaway"
          :disable="!quoteStore.data?.isLayawayAvailable || quoteStore.pending"
          color="secondary"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-weight-bold">
          Enjoy KYGUNCO's Convenient Firearm Layaway Program
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-slide-transition>
      <q-card-section
        v-show="checkoutStore.isLayaway"
        class="column q-gutter-sm text-body2 no-scroll"
      >
        <div>
          With our layaway program, you have the convenience of
          <span class="text-weight-bold">up to 90 days</span> to pay off your
          selected firearm. To get started, simply place a
          <span class="text-weight-bold">30% non-refundable down payment</span>
          of the total cost of your firearm. This down payment ensures that your
          chosen firearm is reserved exclusively for you during the layaway
          period.
        </div>
        <div>
          We've made it easy for you to manage your payments. Through your online
          account, you can make payments at any time that suits you best. We
          understand that everyone's financial situation is unique, so there are
          no monthly payments required. You have the freedom to make payments that
          fit within your budget and schedule.
        </div>
      </q-card-section>
    </q-slide-transition>
  </q-card>
</template>

<script lang="ts" setup>
const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
</script>
