<template>
  <section>
    <div
      v-if="extras"
      class="column q-gutter-md q-mb-md"
    >
      <CheckoutOptionLayaway />
      <CheckoutOptionInsurance />
      <CheckoutOptionGiftCard />
    </div>

    <div class="row justify-around">
      <div
        v-for="method in methods"
        :key="method.key"
        class="col-12 col-sm-6"
      >
        <component :is="method.component" />
      </div>
    </div>

    <div
      v-if="checkoutStore.paymentMethod == 'CreditCard'"
      class="row flex-center q-mt-md"
    >
      <LazyPaymentCreditCard
        v-model="checkoutStore.creditCard"
        :types="['visa', 'mastercard', 'discover']"
        class="col"
        style="max-width: 500px"
      />
    </div>

    <q-stepper-navigation>
      <q-btn
        :disable="!checkoutStore.isPaymentValid || quoteStore.pending"
        color="primary"
        label="Continue"
        square
        @click="checkoutStore.goToNext()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
import {
  LazyCheckoutOptionCreditCard,
  LazyCheckoutOptionFinancing,
} from '#components';
import type { TransactionType } from '~/types/ecommerce';

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();

const options = [
  {
    key: 'CreditCard' as TransactionType,
    component: LazyCheckoutOptionCreditCard,
  },
  {
    key: 'Financing' as TransactionType,
    component: LazyCheckoutOptionFinancing,
  },
];

const methods = computed(() =>
  quoteStore.data
    ? options.filter(
      o => quoteStore.data?.allowedTransactionTypes.includes(o.key),
    )
    : [],
);

const extras = computed(
  () => !!quoteStore.data?.isLayawayAvailable || !checkoutStore.isPickup,
);
</script>
